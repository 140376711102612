var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('svg',{style:('background:#' + _vm.box.background),attrs:{"id":"svgMain","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":'calc(' + _vm.stationObj.width + ' * ' + _vm.stationObj.zoom + ')',"height":'calc(' + _vm.stationObj.height + ' * ' + _vm.stationObj.zoom + ')'}},[_c('defs'),_c('filter',{attrs:{"id":"solid","x":"0","y":"0","width":"1","height":"1"}},[_c('feFlood',{attrs:{"flood-color":"rgb(255,255,255)"}}),_c('feComposite',{attrs:{"in":"SourceGraphic"}})],1),_c('g',{attrs:{"id":"svgCanvas","x":"0","y":"0","width":"1920","height":"1920","transform":'translate(0,0)' + 'rotate(0,0,0)' + 'scale(' + _vm.stationObj.zoom + ')'}},_vm._l((_vm.svgLists),function(item){return _c('g',{key:item.id,staticStyle:{"cursor":"pointer"},attrs:{"id":item.id,"title":item.title,"transform":'translate(' +
            item.svgPositionX +
            ',' +
            item.svgPositionY +
            ')' +
            'rotate(' +
            (item.angle || 0) +
            ',' +
            (item.extend_attr && item.extend_attr.width ? _vm.setCenterA(item, 'x') : 0) +
            ',' +
            (item.extend_attr && item.extend_attr.height ? _vm.setCenterA(item, 'y') : 0) +
            ')' +
            'scale(' +
            item.sizeW +
            ',' +
            item.sizeH +
            ')'}},[_c('SvgComponents',{attrs:{"component_prop":item,"svgInfoData":_vm.svgInfoData}})],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }