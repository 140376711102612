<template>
  <div>
    <svg
      id="svgMain"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :style="'background:#' + box.background"
      :width="'calc(' + stationObj.width + ' * ' + stationObj.zoom + ')'"
      :height="'calc(' + stationObj.height + ' * ' + stationObj.zoom + ')'"
    >
      <defs />
      <filter id="solid" x="0" y="0" width="1" height="1">
        <feFlood flood-color="rgb(255,255,255)" />
        <feComposite in="SourceGraphic" />
      </filter>
      <g
        id="svgCanvas"
        x="0"
        y="0"
        width="1920"
        height="1920"
        :transform="'translate(0,0)' + 'rotate(0,0,0)' + 'scale(' + stationObj.zoom + ')'"
      >
        <g
          v-for="item in svgLists"
          :id="item.id"
          :key="item.id"
          style="cursor:pointer"
          :title="item.title"
          :transform="
            'translate(' +
              item.svgPositionX +
              ',' +
              item.svgPositionY +
              ')' +
              'rotate(' +
              (item.angle || 0) +
              ',' +
              (item.extend_attr && item.extend_attr.width ? setCenterA(item, 'x') : 0) +
              ',' +
              (item.extend_attr && item.extend_attr.height ? setCenterA(item, 'y') : 0) +
              ')' +
              'scale(' +
              item.sizeW +
              ',' +
              item.sizeH +
              ')'
          "
        >
          <SvgComponents :component_prop="item" :svgInfoData="svgInfoData"></SvgComponents>
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
import SvgComponents from './baseEl/svg/components/systemDescription.vue'

export default {
  components: { SvgComponents },
  data() {
    return {
      svgLists: [],
      stationObj: {
        zoom: 1
      },
      svgInfoData: require('./svgData/type.json')
    }
  },
  provide: {},
  created() {},

  mounted() {
    let that = this

    let svgLists = localStorage.getItem('svgListsZL')
    this.svgLists = JSON.parse(svgLists)
    console.log(JSON.parse(svgLists))
  },
  methods: {
    setCenterA(item, type) {
      let w = item.extend_attr.width * item.sizeW
      let h = item.extend_attr.height * item.sizeH
      //  .sizeH,item.extend_attr.width,item.extend_attr.height,item.angle
      // let angle=item.angle
      let center = { x: w, y: h }
      let result = center.x
      // if(angle){
      //    const r = Math.sqrt(w * w + h * h)
      //     center.x = r * Math.sin(angle)
      //     center.y = r * Math.cos(angle)
      // }
      if (type == 'x') {
        result = center.x
      } else {
        result = center.y
      }

      return result
    }
  }
}
</script>
<style scoped lang="scss"></style>
